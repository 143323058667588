<template>
  <div id="wiki" v-show="afterLoading">
    <a-layout id="components-layout-demo-responsive">
      <a-layout-sider
        breakpoint="lg"
        collapsed-width="0"
        width="320"
        @collapse="onCollapse"
        @breakpoint="onBreakpoint"
      >
        <div class="logo" />
        <a-menu theme="dark" mode="inline" :default-selected-keys="['0']">
          <a-menu-item
            v-for="(item, index) in wikiInfo"
            :key="index.toString()"
            @click="jumpTo(index)"
          >
            <span class="nav-text">{{ item.title }}</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <!-- <a-layout-header :style="{ background: '#fff', padding: 0 }" /> -->
        <a-layout-content :style="{ margin: '24px 16px 0' }">
          <div class="wikiTitle">
            <h1>Filecoin 知识库</h1>
            <h3>Filecoin Wiki</h3>
          </div>
          <div class="wiki" @scroll="scrollEvent">
            <a-collapse v-model="activeKey" :bordered="false">
              <a-collapse-panel
                :header="item.title"
                v-for="(item, index) in wikiInfo"
                :key="index.toString()"
              >
                <p v-html="item.content"></p>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapState } from "vuex";
import { Collapse, Menu, Layout } from "ant-design-vue";
Vue.use(Collapse).use(Menu).use(Layout);

export default {
  asyncData: function ({ store }) {
    return store.dispatch("GET_WIKI"); // 返回promise
  },
  computed: mapState({
    wikiInfo: (state) => state.wiki, // 预取基本信息
  }),
  data() {
    return {
      activeKey: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      activeClass: 0,
      afterLoading: false,
    };
  },
  created() {},
  mounted() {
    this.afterLoading = true;
  },
  methods: {
    onCollapse(collapsed, type) {
      console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      console.log(broken);
    },
    jumpTo(index) {
      this.activeClass = index;
      let jump = document.querySelectorAll(".ant-collapse-item");
      jump[index].scrollIntoView({ block: "start", behavior: "smooth" });
    },
    scrollEvent(e) {
      let scrollItems = document.querySelectorAll(".ant-collapse-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.activeClass = i;
          break;
        }
      }
      // 滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ===
        e.srcElement.scrollHeight
      ) {
        this.activeClass = this.wikiInfo.length - 1;
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
#wiki {
  height: calc(~"100% - 65px");
  /deep/.ant-layout {
    height: 100%;
    .ant-layout-sider {
      .ant-layout-sider-children {
        ul {
          li {
            margin-top: 0 !important;
            span {
              font-size: 1.2rem;
            }
          }
        }
      }
      .ant-layout-sider-zero-width-trigger {
        top: 85px;
        line-height: 35px;
      }
    }
    .ant-layout-sider-below {
      z-index: 2;
      position: fixed;
      left: 0;
      top: 61px;
      height: calc(~"100% - 65px");
    }
    .wikiTitle {
      margin-bottom: 2rem;
      border-bottom: 1px solid #e3ebf6;
      h1 {
        font-size: 2rem;
      }
      h3 {
        margin-bottom: 2rem;
        font-size: 1.4rem;
        color: #95aac9;
      }
    }
  }
  .wiki {
    height: calc(~"100% - 65px");
    /deep/.ant-collapse-header {
      font-size: 1.4rem;
      font-weight: 700;
    }
    .ant-collapse-content {
      font-size: 1.2rem;
    }
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 768px) {
}
</style>